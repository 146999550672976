import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';

const toStringOrEmpty = (value: any): string => `${value ?? ' '}`;

const formatLocation = ({ city, state, country, lat, lon }): string => {
  return `${toStringOrEmpty(city)}, ${toStringOrEmpty(state)}, ${toStringOrEmpty(country)}, ${toStringOrEmpty(lat)}, ${toStringOrEmpty(lon)}`;
};

export function mapToAdobeSearchCriteria(searchCriteria: AvailableLoadSearchCriteriaJSON): AdobeSearchCriteria {
  if (!searchCriteria) {
    return null;
  }

  const {
    loadNumber,
    originCity,
    originStateProvinceCode,
    equipmentLengthMax,
    originCountryCode,
    originLatitude,
    originLongitude,
    destinationCity,
    destinationStateProvinceCode,
    destinationCountryCode,
    destinationLatitude,
    destinationLongitude,
    pickupStart,
    pickupEnd,
    pageSize,
    specializedEquipmentCode,
    mode,
    teamLoad,
    milesMin,
    milesMax,
    weightMin,
    weightMax,
    originRadiusMiles,
    destinationRadiusMiles,
    carrierTierAvailable,
    stfLoad,
    webExclusive,
  } = searchCriteria;

  const origin = formatLocation({
    city: originCity,
    state: originStateProvinceCode,
    country: originCountryCode,
    lat: originLatitude,
    lon: originLongitude,
  });

  const destination = formatLocation({
    city: destinationCity,
    state: destinationStateProvinceCode,
    country: destinationCountryCode,
    lat: destinationLatitude,
    lon: destinationLongitude,
  });

  return {
    loadNumber,
    equipmentLengthMax,
    origin,
    destination,
    pickupStart,
    pickupEnd,
    pageSize,
    specializedEquipmentCode,
    mode,
    teamLoad,
    milesMin,
    milesMax,
    weightMin,
    weightMax,
    originRadiusMiles,
    destinationRadiusMiles,
    carrierTierAvailable: carrierTierAvailable?.toString(),
    stfLoad: stfLoad?.toString(),
    webExclusive: webExclusive?.toString(),
  };
}


const mapLoadData = (load: AvailableLoadSummary, index: number): KeyValuePair => ({
  position: index + 1,
  rate: load.totalCost,
  number: load.number,
  categories: load.categories,
  tag: load.tags,
  carrierTier: load.carrierTier,
});

const formatToAdobeListVar = (result: string, load: KeyValuePair) => {
  const loadStringValues = Object
    .entries(load)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}:[${value ?? null}]`
      }

      return `${key}:${value ?? null}`
    })
    .join(',');
  return result ? `${result}|${loadStringValues}` : `${loadStringValues}`;
}

export function mapToAdobeSearchResults(searchResults: AvailableLoadSummary[]): string {
  if (!searchResults) {
    return null;
  }

  return searchResults.map(mapLoadData).reduce(formatToAdobeListVar, '');
}

export function mapToAdobeLoadDetail(position: number, load: AvailableLoadSummary) {
  return {
    positionOnPage: position,
    number: load.number,
    weight: load.weight,
    mode: load.equipmentCode,
    loadDistance: load.distance,
    rate: load.binRateCost?.totalCost ?? null,
    stopCount: load.stopCount,
    origin: `${load.originCity}, ${load.originStateProvinceCode}, ${load.originCountryCode}, ${load.originLatitude}, ${load.originLongitude}`,
    finalDestination: `${load.destinationCity}, ${load.destinationStateProvinceCode}, ${load.destinationCountryCode}, ${load.destinationLatitude}, ${load.destinationLongitude}`,
    carrierTier: load.carrierTier,
    stf: load.isRegulatedByStf.toString(),
    webExclusive: load.isWebExclusive?.toString(),
  }
}
